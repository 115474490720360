<template>
  <div :style="`display: flex; justify-content: center; padding-top: 10px;`">
    <div id="printDiv" :style="`width: ${entity.styleW}cm;`">
      <div
        v-for="page in pageArr"
        :key="page"
        :style="`position: relative;width: ${entity.styleW}cm;height: ${
          entity.styleH *
          (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1)
        }cm;page-break-after:always;border:1px solid #ccc;`"
      >
        <vue-drag-resize
          v-for="(item, index) in entity.printlist"
          :key="item.Id"
          :isActive="false"
          :preventActiveBehavior="false"
          :isDraggable="false"
          :isResizable="false"
          :aspectRatio="false"
          parentLimitation
          :parentW="getpx(entity.styleW, 'w', 'px')"
          :parentH="
            getpx(
              entity.styleH *
                (Number(entity.printPages) > 1 ? Number(entity.printPages) : 1),
              'h',
              'px'
            )
          "
          :w="item.w"
          :h="item.h"
          :x="item.x"
          :y="item.y"
          :minh="28"
          :minw="80"
        >
          <div
            v-if="item.type === '1'"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-if="item.Keys == 'Range'">
              <div v-if="typeof item.value == 'string'">{{ item.value }}</div>
              <div v-else>{{ item.Key }}：{{ item.value.join(" 至 ") }}</div>
            </div>
            <div v-else-if="item.Keys !== 'Time'">{{ item.value }}</div>
            <div v-else>{{ CreateTime }}</div>
          </div>
          <!-- 段落 -->
          <div
            v-if="item.type == '12'"
            class="duanl"
            :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;text-align:${item.textAlign}`"
          >
            <div v-html="item.value"></div>
          </div>
          <!-- 签章 -->
          <div
            class="imgBox"
            :style="`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            v-if="item.type == '3' && item.Keys == 'cusSeal' && cusSeal"
          >
            <img :src="cusSeal" alt="" />
          </div>
          <div
            :style="`border:1px solid #ccc;border-radius:50%;text-align:center;width: ${item.w};height: ${item.h};line-height: ${item.h}px;flex-wrap: nowrap;`"
            class="imgBox"
            v-if="item.type == '3' && item.Keys == 'comSeal' && comSeal"
          >
            <img :src="comSeal" alt="" />
          </div>
          <div v-if="item.type === '4'" style="width: 100%">
            <table border="1" style="width: 100%">
              <thead style="background-color: #ccc">
                <td
                  v-for="(val, index) in item.columns"
                  :key="val.dataIndex"
                  :style="`width: ${val.width || 50}px;line-height: ${
                    val.lineHeight || 28
                  }px; height: ${val.lineHeight || 28}px; text-align: ${
                    val.dataIndex == 'CreateTime' ? 'left' : 'right'
                  }
                  ;font-weight:${val.bold || 'initial'};font-size:${
                    val.fontSize || 18
                  }px;`"
                >
                  {{ val.title }}
                </td>
                <td style="text-align: center; width: 20px">操作</td>
              </thead>

              <tr
                v-for="(itemdata, index) in pageTableDatalist['table' + page]"
                :key="index"
              >
                <td
                  v-for="(val, index) in item.columns"
                  :key="index"
                  :style="{
                    textAlign: val.dataIndex == 'CreateTime' ? 'left' : 'right',
                  }"
                >
                  <!-- :style="`text-align: ${val.textalign || 'center'};height:${
                      val.textlineHeight || 20
                    }px;overflow: hidden;word-break:break-all;`" -->
                  <span>
                    <span v-if="val.dataIndex == 'CreateTime'">
                      {{ itemdata[val.dataIndex]?itemdata[val.dataIndex].substring(0, 10):'' }}
                    </span>
                    <span v-else>
                      {{
                        itemdata[val.dataIndex]
                          ? toThousands(itemdata[val.dataIndex])
                          : ""
                      }}
                    </span>
                  </span>
                </td>
                <td style="text-align: center; width: 20px">
                  <el-button type="text" @click="toDetai(itemdata)"
                    >详情</el-button
                  >
                </td>
              </tr>
            </table>
          </div>
        </vue-drag-resize>
      </div>
    </div>
    <div class="printBox">
      <div
        class="printSon"
        :style="{
          minWidth: entity.styleW + 'cm',
        }"
      >
        <el-button type="primary" @click="getPdf()">下载</el-button>
        <el-button type="primary" v-print="'printDiv'">打印</el-button>
      </div>
    </div>
    <!-- 小票页面组件 -->
    <ReceiptPage ref="receiptPage" :parentObj="{ ...this }" />
    <!-- 对账函组件 -->
    <Form ref="Form" :parentObj="{ ...this }" />
  </div>
</template>

<script>
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
import { toThousands } from "@/utils/Tools.js";
import Form from "../Overdue/Form.vue";
import ReceiptPage from "../Overdue/Receipt.vue";
export default {
  components: {
    Form,
    ReceiptPage,
  },
  data() {
    return {
      entity: {},
      htmlTitle: "对账函",
      comSeal: "",
      cusSeal: "",
      CreateTime: "",
      Id: "",
      toThousands: toThousands,
      pageArr: [],
      pageTableDatalist: {},
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.$http
      .post(
        "/Base/Base_Reconciliation/GetRecDetail?Id=" + this.$route.query.prop
      )
      .then((res) => {
        console.log(res);
        if (res.Success) {
          this.Id = res.Data.Id;
          this.CreateTime = res.Data.CreateTime.substring(0, 10);
          this.comSeal = res.Data.CompanySeal;
          this.cusSeal = res.Data.CustomerSeal;
          this.entity = JSON.parse(res.Data.SpanContent);

          // 根据表格进行分页
          let tableIndex = this.entity.printlist.findIndex(
            (item) => item.type == "4"
          );
          if (tableIndex !== -1) {
            let tablelist = this.entity.printlist[tableIndex].datalist;
            if (tablelist.length > 10) {
              let num = 0;
              this.pageTableDatalist["table" + num] = [];
              this.pageArr.push(num);
              tablelist.forEach((item, index) => {
                if (index !== 0 && index % 10 == 0) {
                  num += 1;
                  this.pageArr.push(num);
                  this.pageTableDatalist["table" + num] = [];
                }
                this.pageTableDatalist["table" + num].push(item);
              });
            } else {
              this.pageArr.push(0);
              this.pageTableDatalist["table0"] =
                this.entity.printlist[tableIndex].datalist;
            }
          } else {
            this.pageArr.push(0);
          }
        }
      });
  },
  methods: {
    // 去详情
    toDetai(row) {
      this.$http
        .post("/BO/BIlling/GetOrderReturnId?Id=" + row.BillId)
        .then((res) => {
          if (res.Success) {
            if (res.Data.Type == 0) {
              this.$refs.receiptPage.openForm(res.Data.Id);
            } else {
              // 打开回执单页面
              this.$refs.Form.openForm(res.Data.Id);
            }
          }
        });
    },
    // 下载
    handleDownload() {
      this.$http
        .post("/Base/Base_Reconciliation/GetRecPDF?id=" + this.Id)
        .then((res) => {
          if (res.Success) {
            downloadFile(res.Data, this.Id);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },
  },
};
</script>

<style>
#printDiv td {
  padding: 0 10px;
}
#printDiv .a {
  padding: 0 20px;
  border-bottom: 1px solid #000;
}
#printDiv .duanl {
  text-indent: 2em;
}
.imgBox img {
  width: 100%;
  height: 100%;
}
.printBox {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}
.printBox .printSon {
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid #ccc;
}
</style>